import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '14rem'
  }
}))

export default ({
  exhibitor,
  exhibitors,
  setSelectedExhibitor,
  selectedExhibitor
}) => {
  const classes = useStyles()

  const selectExhibitor = e =>
    exhibitors.find(exhibitor => exhibitor._id === e.target.value)

  const _handleExhibitorSelectChange = e => {
    e.preventDefault()
    setSelectedExhibitor(selectExhibitor(e))
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={`form-input ${classes.formControl}`}
    >
      <InputLabel>Select an Exhibitor</InputLabel>
      <Select
        label="Select an Exhibitor"
        name="exhibitor"
        onChange={_handleExhibitorSelectChange}
        required
      >
        {notNilOrEmpty(exhibitors) &&
          mapIndexed((exhibitor, index) => {
            return (
              <MenuItem key={index} value={exhibitor._id}>
                <ListItemText primary={exhibitor.title} />
              </MenuItem>
            )
          })(exhibitors)}
      </Select>
    </FormControl>
  )
}
