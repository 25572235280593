import React from 'react'
import * as R from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button } from '@material-ui/core'
import Swal from 'sweetalert2'

import ExhibitorForm from './ExhibitorForm'
import ExhibitorsDropdown from './ExhibitorsDropdown'

import { nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'

const useStyles = makeStyles(theme => ({
  button: {
    margin: 'auto',
    width: 'auto'
  },
  actionsContainer: {
    width: '100%',
    margin: `2rem 0`,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row'
  }
}))

export default ({ children, ...props }) => {
  const classes = useStyles()
  const [exhibitors, setExhibitorsList] = React.useState(
    Array.isArray(exhibitors) ? exhibitors[0] : []
  )
  const [selectedExhibitor, setSelectedExhibitor] = React.useState()

  const exhibitorFormProps = {
    ...props,
    setUserExhibitor: exhibitor => {
      setSelectedExhibitor(exhibitor)
    },
    exhibitors
  }

  async function currentUserExhibitors() {
    const fetchExhibitors = await props.client
      .fetch(
        `*[_type == "exhibitors" && user._ref == "${props.session.user._id}"]{
                'company_img_url': company_img.asset->url,
                'bc__company_who_are_you_img_url': bc__company_who_are_you_img.asset->url,
                categories[]->{..., subcategories[]->},
                ...
            }`
      )
      .catch(reason => {
        // Track the reason here
        console.log(reason)
        if (typeof window !== 'undefined') {
          window.alert(
            'Failed to login, our service might be down, apologies for the inconvenience.'
          )
        }
      })

    setExhibitorsList(fetchExhibitors)
  }

  React.useEffect(() => {
    currentUserExhibitors()
  }, [])

  React.useEffect(() => {
    if (
      !selectedExhibitor &&
      Array.isArray(currentUserExhibitors) &&
      currentUserExhibitors.length === 1
    ) {
      setSelectedExhibitor(currentUserExhibitors[0])
    }

    if (
      Array.isArray(currentUserExhibitors) &&
      R.gt(R.length(currentUserExhibitors), 1)
    ) {
      setExhibitorsList([...currentUserExhibitors])
    }
  }, [props.session])

  if (!Array.isArray(exhibitors)) {
    return (
      <ExhibitorForm
        {...exhibitorFormProps}
        exhibitors={exhibitors}
        fetchExhibitors={currentUserExhibitors}
      />
    )
  }

  if (!selectedExhibitor) {
    return (
      <Box display="flex" p={1} flexDirection="column" alignItems="center">
        {notNilOrEmpty(props.session.user) && notNilOrEmpty(exhibitors) && (
          <ExhibitorsDropdown
            exhibitors={exhibitors}
            setSelectedExhibitor={setSelectedExhibitor}
          />
        )}
        <div className={classes.actionsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedExhibitor('new')}
            className={classes.button}
          >
            Add New Exhibitor
          </Button>
        </div>
      </Box>
    )
  }

  return (
    <ExhibitorForm
      {...exhibitorFormProps}
      exhibitors={exhibitors}
      exhibitor={selectedExhibitor}
      fetchExhibitors={currentUserExhibitors}
      setUserExhibitor={setSelectedExhibitor}
    />
  )
}
