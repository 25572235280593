import React from 'react'
import sanityClient from '@sanity/client'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import LoginHoc from '../hocs/LoginHoc'
import ExhibitorSelection from '../components/ExhibitorSelection'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false // `false` if you want to ensure fresh data
})

export default () => {
  const [isNewExhibitor, setNewExhibitorState] = React.useState(true)
  return (
    <Layout>
      <SEO
        title={
          isNewExhibitor
            ? 'New Exhibitor | Afwerx Fusion Virtual Tradeshow'
            : 'Edit Exhibitor | Afwerx Fusion Virtual Tradeshow'
        }
      />
      <Hero
        title={isNewExhibitor ? 'New Exhibitor' : 'Edit Exhibitor'}
        className="exhibitor-page"
        img={require('../assets/imgs/exhibitor-hero-default.jpg')}
      />
      <LoginHoc
        sanityClient={client}
        onSuccessfulAuthentication={user => {
          setNewExhibitorState(user.exhibitor != null ? false : true)
        }}
        onSignOut={() => setNewExhibitorState(true)}
      >
        <section className="container intro-text">
          <p className="text--center home-page-intro__title">
            Welcome to Booth Registration!
          </p>
        </section>
        <ExhibitorSelection client={client} />
      </LoginHoc>
    </Layout>
  )
}
