import * as React from 'react'
import bcrypt from 'bcryptjs'
import { DateTime } from 'luxon'
import { useAuth } from '../context/auth'
import LoginForm from '../components/LoginForm'
import Swal from 'sweetalert2'

export default ({
  children,
  sanityClient,
  onSuccessfulAuthentication,
  onSignOut
}) => {
  const [isLoading, setLoadingState] = React.useState(false)
  const { setCurrentUser, session } = useAuth()

  React.useEffect(() => {
    if (session != null && session.user != null) {
      onSuccessfulAuthentication(session.user)
    }
  }, [session])

  const finalizeAuthentication = async (
    user,
    shouldFetchExhibitors = false
  ) => {
    const expires = DateTime.local()
      .plus({ hour: 1 })
      .toSeconds()
    const userSession = await sanityClient.create({
      _type: 'user_session',
      expires,
      user: {
        _type: 'reference',
        _ref: user._id
      }
    })
    const exhibitors = shouldFetchExhibitors
      ? await sanityClient
          .fetch(
            `*[_type == "exhibitors" && user._ref == "${user._id}"]{
                'company_img_url': company_img.asset->url,
                'bc__company_who_are_you_img_url': bc__company_who_are_you_img.asset->url,
                category[]->{...},
                subcategories[]->{_id},
                ...
            }`
          )
          .catch(reason => {
            // Track the reason here
            console.log(reason)
            if (typeof window !== 'undefined') {
              window.alert(
                'Failed to fetch an exhibitor record for your account, our service might be down, apologies for the inconvenience.'
              )
            }
          })
      : undefined
    let userWithContext = { ...user }
    if (Array.isArray(exhibitors) && exhibitors.length > 0) {
      userWithContext = { ...userWithContext, exhibitors }
    }
    await setCurrentUser(userWithContext, userSession)
    onSuccessfulAuthentication(userWithContext)
  }

  const onLoginRequest = async data => {
    setLoadingState(true)
    const users = await sanityClient
      .fetch(`*[_type == "user" && email == "${data.email}"]`)
      .catch(reason => {
        // Track the reason here
        console.log(reason)
        if (typeof window !== 'undefined') {
          window.alert(
            'Failed to login, our service might be down, apologies for the inconvenience.'
          )
        }
      })
    if (!Array.isArray(users) || !users.length) {
      Swal.fire({
        title: 'Login Failed',
        text: 'The provided credentials are not valid!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setLoadingState(false)
      return
    }
    const matchingUser = users.find(user =>
      bcrypt.compareSync(data.password, user.password)
    )
    if (!matchingUser) {
      Swal.fire({
        title: 'Login Failed',
        text: 'The provided credentials are not valid!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setLoadingState(false)
      return
    }
    await finalizeAuthentication(matchingUser, true)
    setLoadingState(false)
  }

  const onUserSignup = async data => {
    setLoadingState(true)
    const users = await sanityClient
      .fetch(`*[_type == "user" && email == "${data.email}"]`)
      .catch(reason => null)
    if (!users) {
      Swal.fire({
        title: 'Sign up failed',
        text: 'Our service is down, please contact support',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setLoadingState(false)
      return
    }
    if (Array.isArray(users) && users.length > 0) {
      Swal.fire({
        title: 'Sign up failed',
        text: 'There is already an account for the provided email!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setLoadingState(false)
      return
    }

    const user = await sanityClient.create({
      _type: 'user',
      email: data.email,
      password: bcrypt.hashSync(data.password, 10),
      active: true
    })
    await finalizeAuthentication(user)
    setLoadingState(false)
  }

  if (!session) {
    return (
      <LoginForm
        onSubmit={onLoginRequest}
        isLoading={isLoading}
        onUserSignup={onUserSignup}
      />
    )
  }

  return React.Children.map(children, child =>
    React.cloneElement(child, {
      session,
      client: sanityClient,
      onSignOut: () => {
        onSignOut()
        setCurrentUser(null)
      }
    })
  )
}
