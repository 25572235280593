import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Typography,
  Link,
} from '@material-ui/core';
import {notNilOrEmpty} from '../lib/Helpers';
import SignupForm from './SignupForm';
import ResetPasswordForm from './ResetPasswordForm';

const useStyles = makeStyles (theme => ({
  button: {
    marginTop: theme.spacing (1),
    marginRight: theme.spacing (1),
  },
  actionsContainer: {
    width: '100%',
    marginBottom: theme.spacing (2),
    '& button': {
      width: '100%',
    },
  },
  form: {
    margin: '0 auto',
    maxWidth: '20rem',
    '& .form-input': {
      width: '80%!important',
    },
  },
  input: {
    marginBottom: '1.5rem',
    width: '100%',
  },
}));

export default ({onSubmit, isLoading, onUserSignup}) => {
  const classes = useStyles ();
  const [shouldDisplaySignUpForm, setSignupFormState] = React.useState (false);
  const [shouldDisplayResetPasswordForm, setResetPasswordFormState] = React.useState(false);
  const [formData, setFormData] = React.useState ({
    email: '',
    password: '',
  });
  function _handleInputChange (e) {
    e.preventDefault ();
    setFormData ({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const toggleSignUpForm = event => {
    event.preventDefault ();
    setSignupFormState (true);
  };

  const toggleResetPasswordForm = event => {
    event.preventDefault ();
    setResetPasswordFormState (true);
  }

  if (shouldDisplayResetPasswordForm) {
    return (
      <ResetPasswordForm
        onCompletion={() => setResetPasswordFormState(false)}
        />
    )
  }

  if (shouldDisplaySignUpForm) {
    return (
      <SignupForm
        onSubmit={onUserSignup}
        isLoading={isLoading}
        onCancel={() => setSignupFormState (false)}
      />
    );
  }

  return (
    <div className="container">
      <div className={`animated fadeIn section-content`}>
        <div className="form-container">
          <div className={classes.form}>
            <Box
              display="flex"
              p={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h5" gutterBottom>
                Sign In
              </Typography>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  label="Email"
                  variant="outlined"
                  color="secondary"
                  name="email"
                  onChange={_handleInputChange}
                  value={formData.email}
                  size="small"
                  classes={{
                    root: classes.input,
                  }}
                />
              </FormControl>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  color="secondary"
                  name="password"
                  onChange={_handleInputChange}
                  value={formData.password}
                  size="small"
                  classes={{
                    root: classes.input,
                  }}
                />
              </FormControl>
              <div className={classes.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit(formData)}
                  className={classes.button}
                  disabled={
                    !(notNilOrEmpty (formData.email) &&
                      notNilOrEmpty (formData.password))
                  }
                >
                {isLoading ? <CircularProgress /> : 'Sign In'}
                </Button>
              </div>
              <Typography variant="caption" className={classes.footerText}>
                <Link href="#" onClick={toggleSignUpForm}>Sign up</Link>
                {' '}
                if you don't have an account, or <Link href="#" onClick={toggleResetPasswordForm}>reset your password</Link>.
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
